import request from "../util/request"
import { ServiceMethods } from "../object/ServiceMethods"
import axios from "axios"
import * as Sentry from "@sentry/react";

export const createCustomServiceMethods = function (host, path, actions, abortController) {

    const url = host + '/' + path

    const getUrl = (params = {}) => {
        params = {
            ...params, ...{
                organization: params.organization_id || axios.defaults.headers.common['X-Organization-Id'],
                obituary: params.obituary_id || axios.defaults.headers.common['X-Obituary-Id'],
                group: params.group_id || axios.defaults.headers.common['X-Group-Id']
            }
        }

        return url.replace(/{(\w+)}/g, (m, m1) => {
            if (!params[m1]) {
                console.error(`Missing param ${m1}`)
            }
            return params[m1] || m
        })
    }

    return new ServiceMethods(

        // CREATE
        (data, options) => async dispatch => {
            dispatch(actions.create.pending(options?.reason))

            await request(getUrl(options?.params), {
                data: data,
                method: 'POST',
                options: options
            })
                .then(response => {
                    dispatch(actions.create.success(options?.return == true ? response.data : null))
                })
                .catch(e => {
                    let error = e.response?.data || e.response || e
                    dispatch(actions.create.failure(error))
                    Sentry.captureException(error)
                })
        },

        // UPDATE LIST
        (data, options) => async dispatch => {
            dispatch(actions.updateList.pending(options?.reason))

            await request(getUrl(options?.params), {
                data: data,
                method: 'PATCH',
                options: options
            })
                .then(response => {
                    dispatch(actions.updateList.success(response.data))
                })
                .catch(e => {
                    let error = e.response?.data || e.response || e
                    dispatch(actions.updateList.failure(error))
                    Sentry.captureException(error)
                })
        },

        // UPDATE BY ID
        (id, data, options) => async dispatch => {
            dispatch(actions.updateById.pending(options?.reason))

            await request(getUrl(options?.params) + '/' + id, {
                data: data,
                method: 'PATCH',
                options: options,
            })
                .then(response => {
                    dispatch(actions.updateById.success(options?.return == true ? response.data : null))
                })
                .catch(e => {
                    let error = e.response?.data || e.response || e
                    dispatch(actions.updateById.failure(error))
                    Sentry.captureException(error)
                })
        },

        // FIND ALL
        (data, options) => async dispatch => {
            const params = data ? Object.keys(data).map(key => key + '=' + data[key]).join('&') : null

            dispatch(actions.findAll.pending())

            await request(getUrl(options?.params) + (params ? '?' + params : ''), {
                method: 'GET',
                ...(abortController ? { signal: abortController().signal } : {})
            })
                .then(response => {
                    if (!Array.isArray(response.data)) {
                        dispatch(actions.findAll.success({ ...response.data, ...(options?.append && { append: true }) }))
                    } else {
                        dispatch(actions.findAll.success(response.data))
                    }
                })
                .catch(e => {
                    let error = e.response?.data || e.response || e
                    dispatch(actions.findAll.failure(error))
                    Sentry.captureException(error)
                })
        },

        // FIND BY ID
        (id, options, data) => async dispatch => {
            const params = data ? Object.keys(data).map(key => key + '=' + data[key]).join('&') : null

            dispatch(actions.findById.pending())

            await request(getUrl(options?.params) + '/' + id + (params ? '?' + params : ''), {
                method: 'GET',
                ...(abortController ? { signal: abortController().signal } : {})
            })
                .then(response => {
                    dispatch(actions.findById.success(response.data))
                })
                .catch(e => {
                    let error = e.response?.data || e.response || e
                    dispatch(actions.findById.failure(error))
                    Sentry.captureException(error)
                })
        },

        // DELETE LIST
        (options, data) => async dispatch => {
            const params = data ? Object.keys(data).map(key => key + '=' + data[key]).join('&') : null

            dispatch(actions.deleteList.pending(options?.reason))

            await request(getUrl(options?.params) + '/' + (params ? '?' + params : ''), {
                method: 'DELETE',
                options: options
            })
                .then(response => {
                    dispatch(actions.deleteList.success())
                })
                .catch(e => {
                    let error = e.response?.data || e.response || e
                    dispatch(actions.deleteList.failure(error))
                    Sentry.captureException(error)
                })
        },

        // DELETE BY ID
        (id, options) => async dispatch => {
            dispatch(actions.deleteById.pending(options?.reason))

            await request(getUrl(options?.params) + '/' + id, {
                method: 'DELETE',
                options: options
            })
                .then(response => {
                    dispatch(actions.deleteById.success())
                })
                .catch(e => {
                    let error = e.response?.data || e.response || e
                    dispatch(actions.deleteById.failure(error))
                    Sentry.captureException(error)
                })
        },

        // RESTORE BY ID
        (id, options) => async dispatch => {

            dispatch(actions.restoreById.pending(options?.reason))

            await request(getUrl(options?.params) + '/' + id + '/restore', {
                method: 'POST',
                options: options
            })
                .then(response => {
                    dispatch(actions.restoreById.success(options?.return == true ? response.data : null))
                })
                .catch(e => {
                    let error = e.response?.data || e.response || e
                    dispatch(actions.restoreById.failure(error))
                    Sentry.captureException(error)
                })
        },

        // CLEAR LIST
        () => dispatch => {
            dispatch(actions.clearList.success())

        },

        // CLEAR ENTITY
        () => dispatch => {
            dispatch(actions.clearEntity.success())
        },

        // CLEAR ALL
        () => dispatch => {
            dispatch(actions.clearAll.success())
        }
    )
}
